<template>
  <BaseLayout>
    <Profile />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout'
import Profile from '@/components/profile/Index.vue'

export default {
  name: 'ProfilePage',
  components: { BaseLayout, Profile }
}
</script>
