<template>
  <div class="profile-page p-y-40">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile'
}
</script>

<style lang="scss" scoped>
.profile-page {
  background-color: #f6f6f6;
}
</style>
